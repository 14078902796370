<!-- EnterpriseStaff.vue -->
<template>
  <div>
    <h3>企业员工列表</h3>
    <Button type="primary" @click="addStaff">新增员工</Button>
    <Table :data="staffList" :columns="staffColumns">
      <template slot-scope="{ row }" slot="action">
        <Button v-if="row.isNew" type="primary" size="small" @click="saveStaff(row)">保存</Button>
        <Button v-else type="primary" size="small" @click="openDetailDialog(row.id)">详情</Button>
        <Button type="error" size="small" @click="deleteStaff(row.id)">删除</Button>
      </template>
    </Table>

    <Modal v-model="showDetailDialog" title="员工详情" width="1000">
      <StaffDetail :staffId="selectedStaffId" :entityName="entityName"/>
    </Modal>
  </div>
</template>

<script>
import { post } from '@/apis/restUtils';
import StaffDetail from './StaffDetail.vue';

export default {
  components: {
    StaffDetail
  },
  props: {
    entityId: String,
    entityName: String
  },
  data() {
    return {
      staffList: [],
      staffColumns: [
        { title: 'ID', key: 'id' },
        {
          title: '姓名',
          key: 'name',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('Input', {
                props: { value: params.row.name },
                on: {
                  input: (val) => {
                    this.$set(this.staffList[params.index], 'name', val);
                  }
                }
              });
            }
            return h('span', params.row.name);
          }
        },
        {
          title: '部门',
          key: 'department',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('Input', {
                props: { value: params.row.department },
                on: {
                  input: (val) => {
                    this.$set(this.staffList[params.index], 'department', val);
                  }
                }
              });
            }
            return h('span', params.row.department);
          }
        },
        {
          title: '电话',
          key: 'phone',
          render: (h, params) => {
            if (params.row.isNew) {
              return h('Input', {
                props: { value: params.row.phone },
                on: {
                  input: (val) => {
                    this.$set(this.staffList[params.index], 'phone', val);
                  }
                }
              });
            }
            return h('span', params.row.phone);
          }
        },
        { title: '操作', slot: 'action' }
      ],
      showDetailDialog: false,
      selectedStaffId: null
    };
  },
  mounted() {
    this.fetchStaffList();
  },
  watch: {
    entityId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fetchStaffList();
        }
      }
    }
  },
  methods: {
    fetchStaffList() {
      post(`/wx/enterprises/staff`, {'enterpriseId': this.entityId}, (response) => {
        this.staffList = response.data.entity.map(item => ({ ...item, isNew: false }));
      });
    },
    openDetailDialog(staffId) {
      this.selectedStaffId = staffId;
      this.showDetailDialog = true;
    },
    addStaff() {
      const newStaff = {
        id: '',
        name: '',
        department: '',
        phone: '',
        isNew: true
      };
      this.staffList.push(newStaff);
    },
    saveStaff(row) {
      const data = {
        enterpriseId: this.entityId,
        name: row.name,
        department: row.department,
        phone: row.phone
      };
      post('/wx/enterprise/saveStaff', data, (response) => {
        if (response && response.data && response.data.status === 'success') {
          this.$Message.success('员工添加成功');
          this.fetchStaffList();
        } else {
          this.$Message.error('员工添加失败');
        }
      });
    },
    deleteStaff(id) {
      this.$Modal.confirm({
        title: '确认删除',
        content: '确定要删除该员工？',
        onOk: () => {
          post(`/wx/enterprises/deleteStaff`, {'id': id}, (response) => {
            if (response && response.data && response.data.status === 'success') {
              this.$Message.success('员工删除成功');
              this.fetchStaffList();
            } else {
              this.$Message.error('员工删除失败');
            }
          });
        }
      });
    }
  }
};
</script>